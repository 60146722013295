import React from "react"
import SEO from "../../components/common/seo"
import { css } from "@emotion/core"

function termsOfService() {
  //mapping 할 데이터
  const serviceTerms = [
    {
      head: `제1조 (목적)`,
      contents: `본 약관은 (주)플랜즈커피(이하 “회사”라 한다)가 무인자판기를 통하여 제공하는 서비스의 이용과 관련하여
회사와 회원의 권리, 의무, 책임사항 기타 필요한 사항을 규정함을 목적으로 합니다.`,
      indentedContents: [],
    },
    {
      head: `제2조 (정의)`,
      contents: `본 약관에서 사용하는 용어의 정의는 다음과 같습니다. 본 약관에서 사용되는 용어 중 본 조에서 정하지 아니한 부분은 관계법령 및 
        일반관례에 따릅니다.`,
      indentedContents: [
        "1.기기”라 함은 회사가 이용자에게 음료 등 제공 서비스를 제공하는 수단이 되는 무인자판기를 의미합니다.",
        `2.플랜즈 서비스(이하 ”서비스“라 합니다)”라 함은 회사가 기기를 통하여 이용자에게 제공하는 음료 등 제공 서비스를 
        의미합니다.`,
        `3.음료 등“이란 회사가 서비스를 통하여 이용자에게 제공하는 커피, 기타 음료를 의미합니다.`,
        `4.회원”이라 함은 회원제 서비스를 이용하고자 회사가 요구하는 정보를 기재하고 이 약관에 동의하여 회사와 이용계약을 
        체결한 사람을 의미합니다.`,
      ],
    },
    {
      head: `제3조 (약관의 효력 및 변경)`,
      contents: ``,
      indentedContents: [
        `1.본 약관은 회원제 서비스를 이용하고자 이 약관에 동의한 모든 회원에 대하여 그 효력이 발생합니다.`,
        `2.회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스의 화면 내 또는 별도의 연결화면에 게시합니다.`,
        `3.회사는 필요하다고 인정되는 경우 본 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를
        명시하여 제1항과 같은 방법으로 그 적용일자 7일 전부터 공지하며, 회원에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 
        전부터 공지하거나 개별 고지합니다.`,
        `4.회원이 전항에 따라 공지된 적용일자 이후에도 서비스를 계속 사용하는 경우 개정된 약관에 동의하는 것으로 간주됩니다. 
        그러나 개정된 약관에 동의하지 아니하는 경우 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.`,
      ],
    },
    {
      head: `제4조 (약관의 해석)`,
      contents: `본 약관에 명시되지 않은 사항과 본 약관의 해석에 관하여는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률
      (이하 “정보통신망법”이라 합니다) 등 관련법령 에서 정한 바에 따릅니다.`,
      indentedContents: [],
    },
    {
      head: `제5조 (서비스의 내용)`,
      contents: ``,
      indentedContents: [
        `1.회사는 기기를 통해 이용자가 선택한 바에 따라 음료 등을 제공하고 회사가 인정한 결제수단으로 그에 대한 대가를 수령합니다.
        `,
        `2.회사가 제공하는 서비스의 내용은 회사의 경영정책상 변경될 수 있으며 변경된 내용은 제3조 제3항의 방법을 준용하여 공지합
        니다.`,
      ],
    },
    {
      head: `제6조 (이용계약의 성립)`,
      contents: ``,
      indentedContents: [
        `1. 서비스를 이용하고자 하는 자가 회사가 제공하는 절차에 따라 회사가 요구하는 항목들을 기재하여 본 약관에 동의 후 
        이용신청을 하면 회사가 이를 승낙함으로써 이용계약이 성립합니다.`,
        `2. 회사는 전항에 따른 회원의 이용신청에 대해 승낙함을 원칙으로 합니다. 다만, 이용신청이 다음 각 호에 해당하는 경우에는 
        승낙하지 않거나 사후에 이용계약을 해지할 수 있습니다.`,
        `1) 기술상 서비스 제공이 불가능한 경우
        `,
        `2) 본 약관 및 관련된 별도의 서비스 준수사항을 위배하거나 위법 또는 부당한 가입신청임이 확인된 경우
        `,
        `3) 기타 회사가 정한 이용신청 요건이 만족되지 않거나 회원의 책임 있는 사유로 인하여 회사가 승낙할 수 없는 경우`,
      ],
    },
    {
      head: `제7조 (이용시간)`,
      contents: `서비스의 이용은 회사의 업무상 또는 기술상의 장애, 기타 특별한 사유가 없는 한 연중 무휴 1일 24시간 서비스를 이용할 수 있습니다. 
      다만, 기기설치 장소의 사정에 따라 서비스 이용시간의 제한이 있을 수 있으며 제8조에 따라 서비스이용이 중단될 수 있습니다. `,
      indentedContents: [],
    },
    {
      head: `제8조 (서비스의 제공의 중지 및 중단)`,
      contents: ``,
      indentedContents: [
        `1. 회사는 정기점검, 시스템의 업그레이드 등으로 인하여 부득이한 경우 관련사항 및 재개시점을 사전 공지한 후 서비스의 제공을 
        일정기간 중지할 수 있습니다.`,
        `2. 회사는 다음 각 호에 해당하는 경우 사전 통지 없이 서비스의 일부 또는 전부의 제공을 중지할 수 있습니다. 
        `,
        `1) 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지했을 경우
        `,
        `2) 천재지변, 전쟁 등 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 
        있는 때`,
        `3) 사전에 인지할 수 없는 하드웨어(디스크 등) 장애, 시스템다운 및 외부 통신회사, 전력회사, 기간통신사업자의 장애 등이 발생한 
        경우
        `,
        `4) 회사가 통제할 수 없는 분산서비스거부(DDos) 등 외부적 침입이 시작된 경우
        `,
        `5) 보안상 시급한 문제로 인하여 긴급한 작업을 요하는 경우

        `,
        `6) 기타 사전 통지 없이 서비스를 중단할 수 밖에 없는 명백한 이유가 있는 경우
        `,
        `3. 회사는 서비스의 중단으로 발생하는 문제에 대해서 귀책사유가 없는 한 책임을 부담하지 않습니다.
        `,
      ],
    },
    {
      head: `제9조 (서비스 이용료 결제)`,
      contents: ``,
      indentedContents: [
        `1. 서비스 이용료 결제는 회원이 회사가 인정한 결제수단을 이용하여 이루어지며 회사는 사용가능한 결제수단과 결제방식을 
        서비스 화면을 통하여 공지합니다.`,
        `2. 서비스 이용료의 금액 기타 이와 관련된 내용은 기기 화면에 명시됩니다.`,
      ],
    },
    {
      head: `제10조 (회사의 의무)`,
      contents: ``,
      indentedContents: [
        `1. 회사는 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령
        에 의한 수사상의 목적으로 관계기관으로부터 요구받은 경우 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 
        않습니다.`,
        `2. 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보 등을 특정 회원의 정보를 구별할 수 없는
        통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 기기의 쿠키를 이용할 수 있습니다.
        `,
        `3. 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 
        사유와 처리 일정을 개별 서비스 화면에 게재하거나 SMS, 문자, 카카오톡 메세지 등을 통하여 회원에게 통지합니다.
        `,
        `4. 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 귀책사유에 의하여 발생한 경우에 
        한하여 회사에서 책임을 부담합니다.`,
        `5. 회사는 서비스의 운영, 유지와 관련 있는 제반법규를 준수합니다.`,
      ],
    },
    {
      head: `제11조 (회원의 의무)`,
      contents: ``,
      indentedContents: [
        `1. 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 아니 됩니다. 
        `,
        `1) 이용신청 또는 변경 시 허위 사실을 기재하거나, 제3자의 정보를 도용, 부정하게 사용하는 행위

        `,
        `3) 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위

        `,
        `4) 서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 
        행위`,
        `5) 이용의사 없이 반복적인 주문 및 취소를 통해 회사의 서비스 제공을 방해하는 행위
        `,
        `6) 기타 불법적이거나 부당한 행위
        `,
        `2. 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 합니다.`,
        `3. 회원이 제1항에 명시된 행위를 한 경우에 회사는 서비스의 일부 또는 전부에 대한 이용제한, 이용계약의 해지 등을 할 수 
        있으며, 회사에 손해가 발생한 경우에는 손해배상의 청구 등의 조치를 할 수 있습니다.`,
        `4. 회사가 제3항에 정한 조치를 할 경우 회사는 SMS, 문자 등으로 통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 
        부득이한 경우, 선 조치 후 사후 통보할 수 있습니다.`,
        `5. 제3항에 의하여 회사가 회원과의 이용계약을 해지하더라도, 해지 이전에 이미 체결된 계약의 완결에 관해서는 본 약관이 
        그대로 적용됩니다.`,
        `6. 제3항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 별도 통지 없이 해당 회원과 관련된 거래를 취소할 수 있고 
        서비스의 결제를 신용카드로 결제한 경우에는 그 신용카드매출을 취소할 수 있습니다.`,
        `7. 제3항에서 정한 바에 따라 이용계약이 종료되는 경우 회원의 재이용신청에 대하여 회사는 그 승낙을 거절할 수 있습니다.`,
        `8. 회원은 서비스 이용을 위해 등록할 경우 현재의 사실과 일치하는 완전한 정보(이하 "등록정보"라 합니다)를 제공하여야 
        합니다.`,
        `9. 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 원활한 서비스를 제공받으실 수 있습니다.`,
      ],
    },
    {
      head: `제12조 (회원에 대한 통지)`,
      contents: `회원에 대한 통지를 하는 경우 회사는 관련 서비스 페이지 내에 게재하거나 SMS, 문자 등으로 할 수 있습니다. 다만, 회사는 불특정 다수 
      회원에 대한 통지의 경우 서비스 게시판 내 게시 등의 방법을 활용할 수 있습니다.`,
      indentedContents: [],
    },
    {
      head: `제13조 (개인정보 관리에 대한 의무와 책임)`,
      contents: ``,
      indentedContents: [
        `1. 회원은 자신의 개인정보 관리를 철저히 하여야 합니다. 회원의 개인정보 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 
        대하여 귀책사유가 없는 한 회사는 책임을 부담하지 않습니다.`,
        `2. 회사는 회원의 개인정보를 사용하는 사람을 회원 또는 회원이 이용을 승낙한 사람으로 간주합니다.`,
      ],
    },
    {
      head: `제14조 (개인정보 등의 변경 및 보호)`,
      contents: ``,
      indentedContents: [
        `1. 회원은 이용신청 시 등록된 정보가 변경되었을 경우에는 즉시 수정하여야 하며 수정하지 않은 정보로 인하여 발생하는 손해는 
        회사에 귀책사유가 없는 한 책임을 지지 않습니다.`,
        `2. 회사는 회원의 정보를 서비스를 제공하기 위한 목적 이외의 용도로 사용하거나 회원의 동의 없이 제3자에게 제공하지 
        않습니다. 단, 다음의 경우는 예외로 합니다.`,
        `1) 서비스 제공에 관한 계약을 이행하기 위한 것으로 결제대행업체 등에게 거래 내역, 정산 내역 제공 등에 필요한 최소한의 
        정보(카드정보 등)를 제공하는 경우`,
        `2) 법령에서 회원의 개인정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우`,
        `3. 회사는 관련법령이 정하는 바에 따라서 회원의 개인정보 등을 보호하기 위하여 노력합니다. 회원의 개인정보 등의 보호에 관해
        서는 관련 법령 및 회사가 정하는 개인정보취급방침에 정한 바에 의합니다.`,
        `4. 회사는 수집된 개인정보 등의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 회원의 동의를 받거나 
        통지를 한 후 위 업무의 일부 또는 전부를 회사가 선정한 회사에 위탁할 수 있습니다.`,
      ],
    },
    {
      head: `제15조 (계약해지 및 이용제한)`,
      contents: ``,
      indentedContents: [
        `1. 회원은 다음 각 호에서 정한 바에 따라 이용계약을 해지할 수 있습니다. 
        `,
        `1) 회원은 언제든지 회사에 이용계약 해지의 의사를 통지함으로써 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기 전에 
        현재 진행 중인 모든 거래를 완료하거나 철회 또는 취소하여야 하며, 회사는 귀책사유가 없는 한 해당 거래에 관하여 책임을 부담
        하지 않습니다.`,
        `2) 회원탈퇴로 인해 발생할 수 있는 사항에 대하여 회사는 귀책사유가 없는 한 책임을 부담하지 아니하며, 이용계약이 종료되면 
        회사가 이용계약에 기초하여 회원에게 부가적으로 제공한 혜택이 함께 종료됩니다.`,
        `2. 회사는 회원이 본 약관이 규정한 회원의 의무를 이행하지 않을 경우, 즉시 이용계약을 해지하거나 또는 서비스 이용 중단 
        조치를 취할 수 있습니다.`,
        `3. 회사는 회원이 이용계약을 체결한 이후에라도 회원의 자격에 제한이 있는 때에는 서비스 이용을 제한할 수 있습니다.`,
      ],
    },
    {
      head: `제16조 (양도 금지)`,
      contents: `회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여, 담보 제공할 수 없습니다.`,
      indentedContents: [],
    },
    {
      head: `제17조 (손해배상)`,
      contents: `회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여, 담보 제공할 수 없습니다.`,
      indentedContents: [
        `1. 회원이 본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 회사에 발생하는 
      손해를 배상하여야 합니다.`,
        `2. 회사는 서비스의 이용과 관련하여 발생하는 회원의 손해에 대하여 관계법령이 정하는 한도내에서 배상책임을 집니다. 단. 
      회원의 과실이 있는 경우에는 해당 부분에 대한 책임이 면제됩니다.`,
        `3. 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 당해 회원은 
      그로 인하여 회사에 발생한 손해를 배상하여야 합니다.`,
      ],
    },
    {
      head: `제18조 (책임제한)`,
      contents: ``,
      indentedContents: [
        `1. 회사는 회원이 자신의 개인정보 및 계정정보 등을 타인에게 제공하거나 회원의 관리소홀로 유출됨으로써 발생하는 피해에 
      대해서 회사는 책임을 부담하지 않습니다.`,
        `2. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스 이용에 장애가 발생하는 경우 서비스 제공에 관한 책임이 
      면제됩니다. 회원의 귀책사유로 인한 서비스의 이용장애의 경우에도 동일합니다.`,
        `3. 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입하지 않습니다.`,
      ],
    },
    {
      head: `제19조 (준거법 및 재판관할)`,
      contents: ``,
      indentedContents: [
        `1. 본 약관과 관련된 사항에 대하여는 대한민국법을 준거법으로 합니다.`,
        `2. 회사와 회원 사이에 발생한 분쟁에 관한 소송의 관할법원은 민사소송법에 따라 정합니다.`,
      ],
    },
  ]

  return (
    <>
      <SEO title="TermsOfService" />
      <main css={terms__of__service__wrap}>
        <h1>서비스 이용 약관</h1>
        <hr />
        {serviceTerms.map(obj => {
          return (
            <div css={service__term}>
              <h2>{obj.head}</h2>
              <article>{obj.contents}</article>
              <div>
                {obj.indentedContents.map(list => {
                  return <p>{list}</p>
                })}
              </div>
            </div>
          )
        })}
        <div css={service__term__bottom}>-부칙-</div>
        <p css={service__term__bottom__contents}>
          본 약관은 2020년 8월 31일부터 적용됩니다.
        </p>
      </main>
    </>
  )
}

const terms__of__service__wrap = css`
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (min-width: 800px) {
    width: 760px;
  }
  word-break: keep-all;

  h1 {
    font-family: Spoqa Han Sans;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  hr {
    margin-top: 28px;
    margin-bottom: 37px;
    border: 1px solid #707070;
  }
`
const service__term = css`
  margin-bottom: 67px;
  font-family: Spoqa Han Sans;

  h2 {
    margin-bottom: 37px;
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #3e3e3e;
  }

  article {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }

  div {
    margin-top: 37px;
    margin-left: 50px;

    p {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
`

const service__term__bottom = css`
  font-family: Spoqa Han Sans;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  margin-top: 82px;
  margin-bottom: 37px;
  text-align: center;
`

const service__term__bottom__contents = css`
  font-family: Spoqa Han Sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  text-align: center;
`
export default termsOfService
